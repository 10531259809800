import girlAvatar from '@/assets/start/avatar-girl.webp';
import manAvatar from '@/assets/start/avatar-man.webp';
import womanAvatar from '@/assets/start/avatar-woman.webp';
import girlAvatar768 from '@/assets/start/avatar_girl_768.svg';
import manAvatar768 from '@/assets/start/avatar_man_768.svg';
import womanAvatar768 from '@/assets/start/avatar_woman_768.svg';

import effects_icon_1 from '@/assets/start/effects-1.svg';
import effects_icon_2 from '@/assets/start/effects-2.svg';
import effects_icon_3 from '@/assets/start/effects-3.svg';
import effects_icon_4 from '@/assets/start/effects-4.svg';

import howTrain1 from '@/assets/how-train-1.svg';
import howTrain2 from '@/assets/how-train-2.svg';
import howTrain3 from '@/assets/how-train-3.svg';
import HowTrain1_768 from '@/assets/how-train-768-1.svg';
import HowTrain2_768 from '@/assets/how-train-768-2.svg';
import HowTrain3_768 from '@/assets/how-train-768-3.svg';

import avatar_320_1 from '@/assets/review/avatars/avatar-1-320.webp';
import avatar_320_2 from '@/assets/review/avatars/avatar-2-320.webp';
import avatar_320_3 from '@/assets/review/avatars/avatar-3-320.webp';
import avatar_768_1 from '@/assets/review/avatars/avatar-1-768.webp';
import avatar_768_2 from '@/assets/review/avatars/avatar-2-768.webp';
import avatar_768_3 from '@/assets/review/avatars/avatar-3-768.webp';

import red_brain from '@/assets/quiz/brain/brain-red.svg';
import purple_brain from '@/assets/quiz/brain/brain-purple.svg';
import blue_brain from '@/assets/quiz/brain/brain-blue.svg';
import green_brain from '@/assets/quiz/brain/brain-green.svg';
import yellow_brain from '@/assets/quiz/brain/brain-yellow.svg';

import red_brain_2 from '@/assets/quiz/brain/brain-red-768.svg';
import purple_brain_2 from '@/assets/quiz/brain/brain-purple-768.svg';
import blue_brain_2 from '@/assets/quiz/brain/brain-blue-768.svg';
import green_brain_2 from '@/assets/quiz/brain/brain-green-768.svg';
import yellow_brain_2 from '@/assets/quiz/brain/brain-yellow-768.svg';

import img_welcome_cognitive from '@/assets/quiz/welcome-cognitive.svg';
import img_welcome_phrases from '@/assets/quiz/welcome-phrases.svg';
import img_welcome_plan from '@/assets/quiz/welcome-plan.svg';
import img_welcome_cognitive_2 from '@/assets/quiz/welcome-cognitive-768.svg';
import img_welcome_phrases_2 from '@/assets/quiz/welcome-phrases-768.svg';
import img_welcome_plan_2 from '@/assets/quiz/welcome-plan-768.svg';

//// Four Wariant Assets \\\\

import red_icon_1 from '@/assets/four_variant/320/red-icon-1.svg';
import red_icon_2 from '@/assets/four_variant/320/red-icon-2.svg';
import red_icon_3 from '@/assets/four_variant/320/red-icon-3.svg';
import red_icon_4 from '@/assets/four_variant/320/red-icon-4.svg';

import purple_icon_1 from '@/assets/four_variant/320/purple-icon-1.svg';
import purple_icon_2 from '@/assets/four_variant/320/purple-icon-2.svg';
import purple_icon_3 from '@/assets/four_variant/320/purple-icon-3.svg';
import purple_icon_4 from '@/assets/four_variant/320/purple-icon-4.svg';

import blue_icon_1 from '@/assets/four_variant/320/blue-icon-1.svg';
import blue_icon_2 from '@/assets/four_variant/320/blue-icon-2.svg';
import blue_icon_3 from '@/assets/four_variant/320/blue-icon-3.svg';
import blue_icon_4 from '@/assets/four_variant/320/blue-icon-4.svg';

import green_icon_1 from '@/assets/four_variant/320/green-icon-1.svg';
import green_icon_2 from '@/assets/four_variant/320/green-icon-2.svg';
import green_icon_3 from '@/assets/four_variant/320/green-icon-3.svg';
import green_icon_4 from '@/assets/four_variant/320/green-icon-4.svg';

import yellow_icon_1 from '@/assets/four_variant/320/yellow-icon-1.svg';
import yellow_icon_2 from '@/assets/four_variant/320/yellow-icon-2.svg';
import yellow_icon_3 from '@/assets/four_variant/320/yellow-icon-3.svg';
import yellow_icon_4 from '@/assets/four_variant/320/yellow-icon-4.svg';

import red_icon_1_768 from '@/assets/four_variant/768/red-icon-1.svg';
import red_icon_2_768 from '@/assets/four_variant/768/red-icon-2.svg';
import red_icon_3_768 from '@/assets/four_variant/768/red-icon-3.svg';
import red_icon_4_768 from '@/assets/four_variant/768/red-icon-4.svg';

import purple_icon_1_768 from '@/assets/four_variant/768/purple-icon-1.svg';
import purple_icon_2_768 from '@/assets/four_variant/768/purple-icon-2.svg';
import purple_icon_3_768 from '@/assets/four_variant/768/purple-icon-3.svg';
import purple_icon_4_768 from '@/assets/four_variant/768/purple-icon-4.svg';

import blue_icon_1_768 from '@/assets/four_variant/768/blue-icon-1.svg';
import blue_icon_2_768 from '@/assets/four_variant/768/blue-icon-2.svg';
import blue_icon_3_768 from '@/assets/four_variant/768/blue-icon-3.svg';
import blue_icon_4_768 from '@/assets/four_variant/768/blue-icon-4.svg';

import green_icon_1_768 from '@/assets/four_variant/768/green-icon-1.svg';
import green_icon_2_768 from '@/assets/four_variant/768/green-icon-2.svg';
import green_icon_3_768 from '@/assets/four_variant/768/green-icon-3.svg';
import green_icon_4_768 from '@/assets/four_variant/768/green-icon-4.svg';

import yellow_icon_1_768 from '@/assets/four_variant/768/yellow-icon-1.svg';
import yellow_icon_2_768 from '@/assets/four_variant/768/yellow-icon-2.svg';
import yellow_icon_3_768 from '@/assets/four_variant/768/yellow-icon-3.svg';
import yellow_icon_4_768 from '@/assets/four_variant/768/yellow-icon-4.svg';

//// For Router \\\\
import three_variant_img_1 from '@/assets/quiz/three_variant/three-variant-img-1.svg';
import three_variant_img_2 from '@/assets/quiz/three_variant/three-variant-img-2.svg';
import three_variant_img_1_768 from '@/assets/quiz/three_variant/three-variant-img-1-768.svg';
import three_variant_img_2_768 from '@/assets/quiz/three_variant/three-variant-img-2-768.svg';

import three_variant_1_icon_1 from '@/assets/quiz/three_variant/low-intensity.svg';
import three_variant_1_icon_2 from '@/assets/quiz/three_variant/normal-intensity.svg';
import three_variant_1_icon_3 from '@/assets/quiz/three_variant/high-intensity.svg';
import three_variant_1_icon_1_768 from '@/assets/quiz/three_variant/three-variant-intensity-1-768.svg';
import three_variant_1_icon_2_768 from '@/assets/quiz/three_variant/three-variant-intensity-2-768.svg';
import three_variant_1_icon_3_768 from '@/assets/quiz/three_variant/three-variant-intensity-3-768.svg';

import three_variant_2_icon_1 from '@/assets/quiz/three_variant/smiley.svg';
import three_variant_2_icon_2 from '@/assets/quiz/three_variant/smiley-happy.svg';
import three_variant_2_icon_3 from '@/assets/quiz/three_variant/smiley-very-happy.svg';
import three_variant_2_icon_1_768 from '@/assets/quiz/three_variant/smiley-768.svg';
import three_variant_2_icon_2_768 from '@/assets/quiz/three_variant/smiley-happy-768.svg';
import three_variant_2_icon_3_768 from '@/assets/quiz/three_variant/smiley-very-happy-768.svg';

import emoji_1 from '@/assets/quiz/badly.svg';
import emoji_2 from '@/assets/quiz/good.svg';
import emoji_3 from '@/assets/quiz/excellent.svg';
import emoji_768_1 from '@/assets/quiz/badly-768.svg';
import emoji_768_2 from '@/assets/quiz/good-768.svg';
import emoji_768_3 from '@/assets/quiz/excellent-768.svg';

import fb_1 from '@/assets/subscribe/review-brain.svg';
import fb_2 from '@/assets/review/first-block-2.svg';
import fb_3 from '@/assets/review/first-block-3.svg';

import at_1 from '@/assets/review/be-able-to-1.svg';
import at_2 from '@/assets/review/be-able-to-3.svg';
import at_3 from '@/assets/review/be-able-to-4.svg';
import at_4 from '@/assets/review/be-able-to-2.svg';

import icon_1 from '@/assets/review/slider_1/icon-1.svg';
import icon_2 from '@/assets/review/slider_1/icon-2.svg';
import icon_3 from '@/assets/review/slider_1/icon-3.svg';
import icon_4 from '@/assets/review/slider_1/icon-4.svg';
import icon_5 from '@/assets/review/slider_1/icon-5.svg';
import icon_6 from '@/assets/review/slider_1/icon-6.svg';
import icon_7 from '@/assets/review/slider_1/icon-7.svg';
import icon_8 from '@/assets/review/slider_1/icon-8.svg';
import icon_9 from '@/assets/review/slider_1/icon-9.svg';
import icon_10 from '@/assets/review/slider_1/icon-10.svg';
import icon_11 from '@/assets/review/slider_1/icon-11.svg';
import icon_12 from '@/assets/review/slider_1/icon-12.svg';
import icon_13 from '@/assets/review/slider_1/icon-13.svg';
import icon_14 from '@/assets/review/slider_1/icon-14.svg';
import icon_15 from '@/assets/review/slider_1/icon-15.svg';
import icon_16 from '@/assets/review/slider_1/icon-16.svg';
import icon_17 from '@/assets/review/slider_1/icon-17.svg';
import icon_18 from '@/assets/review/slider_1/icon-18.svg';
import icon_19 from '@/assets/review/slider_1/icon-19.svg';

import chart_1 from '@/assets/slider-two/320/Chart-1.svg';
import chart_2 from '@/assets/slider-two/320/Chart.svg';
import chart_3 from '@/assets/slider-two/320/Chart-2.svg';
import chart_4 from '@/assets/slider-two/320/Chart-3.svg';
import chart_5 from '@/assets/slider-two/320/Chart-4.svg';

import you_get_1 from '@/assets/subscribe/you-get-1.svg';
import you_get_2 from '@/assets/subscribe/you-get-2.svg';
import you_get_3 from '@/assets/subscribe/you-get-3.svg';
import you_get_4 from '@/assets/subscribe/you-get-4.svg';
import you_get_5 from '@/assets/subscribe/you-get-5.svg';

import end_img_1 from '@/assets/review/end-block-1.svg';
import end_img_2 from '@/assets/review/end-block-2.svg';

import subscription_icon_1 from '@/assets/subscribe/3day.svg';
import subscription_icon_2 from '@/assets/subscribe/1year.svg';
import subscription_icon_3 from '@/assets/subscribe/lifetime.svg';

import train_benefits_icon_1 from '@/assets/close/train-benefits-1.svg';
import train_benefits_icon_2 from '@/assets/close/train-benefits-2.svg';
import train_benefits_icon_3 from '@/assets/close/train-benefits-3.svg';
import train_benefits_icon_4 from '@/assets/close/train-benefits-4.svg';
import train_benefits_icon_5 from '@/assets/close/train-benefits-5.svg';



export const effects_data = [
  {
    id: 1,
    icon: effects_icon_1,
    number: '+17%',
    description: 'to reaction speed in 1 week',
    color: 'purple',
  },
  {
    id: 2,
    icon: effects_icon_2,
    number: 'x1,6',
    description: 'to focus on tasks in 1 month',
    color: 'blue',
  },
  {
    id: 3,
    icon: effects_icon_3,
    number: 'x2,1',
    description: 'to attention to details in 2 months',
    color: 'green',
  },
  {
    id: 4,
    icon: effects_icon_4,
    number: '+19%',
    description: 'to memory in 2–3 weeks',
    color: 'skin',
  }
]

export const benefits_data = [
  {
    id: 1,
    img: {
      320: girlAvatar,
      768: girlAvatar768,
    },
    h: 'Younger age',
    p: ['Improving the learning process in educational institutions', 'Prevention of attention deficit disorder (ADD)']
  },
  {
    id: 2,
    img: {
      320: manAvatar,
      768: manAvatar768,
    },
    h: 'Middle age',
    p: ['Increase personal productivity and efficiency', 'Make fewer mistakes and omissions at work', 'Keep your brain alert throughout the day']
  },
  {
    id: 3,
    img: {
      320: womanAvatar,
      768: womanAvatar768,
    },
    h: 'Elderly age',
    p: ['Keep your mind sharp and clear longer', 'Prevention of age-related diseases caused by decreased intellectual load']
  }
]

export const howTrain_data = [
  {
    id: 1,
    img: {
      320: howTrain1,
      768: HowTrain1_768,
    },
    h: 'Assess your abilities',
    p: 'Take an introductory test to get an assessment of your current level of memory, attention and thinking skill. A personal development program will be created for you.',
  },
  {
    id: 2,
    img: {
      320: howTrain2,
      768: HowTrain2_768,
    },
    h: 'Do daily training',
    p: 'Do warm-ups and workouts for your brain to develop it daily. It will only take you 15 minutes a day.',
  },
  {
    id: 3,
    img: {
      320: howTrain3,
      768: HowTrain3_768,
    },
    h: 'Keep track of your development',
    p: 'Keep track of your workout statistics, improve your performance, compare your achievements with other users.',
  },
]

export const reviews_data = [
  {
    id: 1,
    rating: 5,
    avatar: {
      320: avatar_320_1,
      768: avatar_768_1,
    },
    name: 'Daniel Carter',
    text: "Brainy Train has transformed my daily commute into a mental workout session with its diverse range of puzzles — it's my go-to app for keeping my mind sharp and engaged.",
  },
  {
    id: 2,
    rating: 5,
    avatar: {
      320: avatar_320_2,
      768: avatar_768_2,
    },
    name: 'Isabella Davis',
    text: 'As a busy professional, Brainy Train fits seamlessly into my routine, offering quick yet challenging exercises that I genuinely enjoy. The progress tracking keeps me motivated to consistently boost my cognitive skills',
  },
  {
    id: 3,
    rating: 5,
    avatar: {
      320: avatar_320_3,
      768: avatar_768_3,
    },
    name: 'Ethan Williams',
    text: 'Brainy Train has become a favorite in our family; the visually appealing games are not only entertaining but also a fantastic way to bring everyone together for some friendly brain competition',
  },
]

//// For Router \\\\

export const welcome_cognitive_abilities = {
  h: 'Time to determine your <span class="green">cognitive abilities</span>',
  p: 'Find out how developed your brain is and get a personal development plan',
  img: {
    320: img_welcome_cognitive,
    768: img_welcome_cognitive_2,
  }
}
export const welcome_phrases = {
  h: 'Choose the <span class="green">phrases</span> that describe you best',
  p: 'Try to answer without thinking for a long time.',
  img: {
    320: img_welcome_phrases,
    768: img_welcome_phrases_2,
  }
}
export const welcome_plan = {
  h: 'Adjust your <span class="green">plan</span> to your needs easily!',
  p: 'We have a couple of questions left to get to know your motivation better',
  img: {
    320: img_welcome_plan,
    768: img_welcome_plan_2,
  }
}

//// For Router \\\\

export const redBrain = {
  text: 'How would you rate your <span class="green">memory</span> abilities?',
  img: {
    320: red_brain,
    768: red_brain_2,
  }
}
export const purpleBrain = {
  text: 'How would you evaluate your success in <span class="green">problem solving</span>?',
  img: {
    320: purple_brain,
    768: purple_brain_2,
  }
}
export const blueBrain = {
  text: 'How well developed is your <span class="green">attention</span>?',
  img: {
    320: blue_brain,
    768: blue_brain_2,
  }
}
export const greenBrain = {
  text: 'How good is your <span class="green">mental flexibility</span>?',
  img: {
    320: green_brain,
    768: green_brain_2,
  }
}
export const yellowBrain = {
  text: 'How would you rate your <span class="green">logical</span> ability?',
  img: {
    320: yellow_brain,
    768: yellow_brain_2,
  }
}

//// For Router \\\\

export const discribe_memory = {
  h: 'Which phrase best describes your <span class="green">memory</span>?',
  active_module_data: [
    {
      id: 1,
      icon: {
        320: red_icon_1,
        768: red_icon_1_768,
      },
      text: 'I have difficulty remembering people and places I meet and see',
    },
    {
      id: 2,
      icon: {
        320: red_icon_2,
        768: red_icon_2_768,
      },
      text: 'My memory issues make it hard to recall moments from my past',
    },
    {
      id: 3,
      icon: {
        320: red_icon_3,
        768: red_icon_3_768,
      },
      text: 'I have trouble finding things in clutter',
    },
    {
      id: 4,
      icon: {
        320: red_icon_4,
        768: red_icon_4_768,
      },
      text: 'I often forget to buy items on my shopping list.',
    }
  ]
}
export const discribe_problem_solving = {
  h: 'Which phrase best describes your <span class="green">problem-solving</span> ability?',
  active_module_data: [
    {
      id: 1,
      icon: {
        320: purple_icon_1,
        768: purple_icon_1_768,
      },
      text: 'I find it difficult to make quick decisions',
    },
    {
      id: 2,
      icon: {
        320: purple_icon_2,
        768: purple_icon_2_768,
      },
      text: "I don't have a structured approach to decision making",
    },
    {
      id: 3,
      icon: {
        320: purple_icon_3,
        768: purple_icon_3_768,
      },
      text: "Sometimes I can't handle creating new ideas",
    },
    {
      id: 4,
      icon: {
        320: purple_icon_4,
        768: purple_icon_4_768,
      },
      text: 'I have difficulty solving math problems in my mind',
    }
  ]
}
export const discribe_attention = {
  h: 'Which phrase best describes your <span class="green">attention</span>?',
  active_module_data: [
    {
      id: 1,
      icon: {
        320: blue_icon_1,
        768: blue_icon_1_768,
      },
      text: 'I often find it difficult to stay focused when working on tasks',
    },
    {
      id: 2,
      icon: {
        320: blue_icon_2,
        768: blue_icon_2_768,
      },
      text: 'Frequent distractions prevent me from completing projects',
    },
    {
      id: 3,
      icon: {
        320: blue_icon_3,
        768: blue_icon_3_768,
      },
      text: 'I often have to or re-check things because my mind wanders',
    },
    {
      id: 4,
      icon: {
        320: blue_icon_4,
        768: blue_icon_4_768,
      },
      text: 'I find it difficult to stay involved in conversations',
    }
  ]
}
export const discribe_mental_flexibility = {
  h: 'Which phrase best describes your <span class="green">mental flexibility</span>?',
  active_module_data: [
    {
      id: 1,
      icon: {
        320: green_icon_1,
        768: green_icon_1_768,
      },
      text: 'I resist change and have difficulty adapting to new situations',
    },
    {
      id: 2,
      icon: {
        320: green_icon_2,
        768: green_icon_2_768,
      },
      text: 'I find it difficult to consider different points of view',
    },
    {
      id: 3,
      icon: {
        320: green_icon_3,
        768: green_icon_3_768,
      },
      text: 'I struggle with embracing different approaches',
    },
    {
      id: 4,
      icon: {
        320: green_icon_4,
        768: green_icon_4_768,
      },
      text: "I've encountered difficulties in finding compromise in conflicts",
    }
  ]
}
export const discribe_logical = {
  h: 'Which phrase best describes your <span class="green">logical</span> abilities?',
  active_module_data: [
    {
      id: 1,
      icon: {
        320: yellow_icon_1,
        768: yellow_icon_1_768,
      },
      text: 'I find it challenging to identify cause-and-effect relationships',
    },
    {
      id: 2,
      icon: {
        320: yellow_icon_2,
        768: yellow_icon_2_768,
      },
      text: 'I tend to misinterpret instructions or information',
    },
    {
      id: 3,
      icon: {
        320: yellow_icon_3,
        768: yellow_icon_3_768,
      },
      text: 'I find it difficult to identify patterns',
    },
    {
      id: 4,
      icon: {
        320: yellow_icon_4,
        768: yellow_icon_4_768,
      },
      text: 'I often make decisions based on emotions rather than logic',
    }
  ]
}

//// For Router \\\\

export const three_variant_intensely = {
  h: 'How <span class="green">intensely</span> do you want to <span class="green">train</span>?',
  img: {
    320: three_variant_img_1,
    768: three_variant_img_1_768,
  },
  active_block_data: [
    {
      id: 1,
      icon: {
        320: three_variant_1_icon_1,
        768: three_variant_1_icon_1_768,
      },
      main_text: 'Low intensity',
      additional_text: 'minutes / day',
      subscribe_data: '3',
    },
    {
      id: 2,
      icon: {
        320: three_variant_1_icon_2,
        768: three_variant_1_icon_2_768,
      },
      main_text: 'Normal intensity',
      additional_text: 'minutes / day',
      subscribe_data: '5',
    },
    {
      id: 3,
      icon: {
        320: three_variant_1_icon_3,
        768: three_variant_1_icon_3_768,
      },
      main_text: 'High intensity',
      additional_text: 'minutes / day',
      subscribe_data: '10',
    }
  ]
}
export const three_variant_motivated = {
  h: 'How <span class="green">motivated</span> are you to start mental training?',
  img: {
    320: three_variant_img_2,
    768: three_variant_img_2_768,
  },
  active_block_data: [
    {
      id: 1,
      icon: {
        320: three_variant_2_icon_1,
        768: three_variant_2_icon_1_768,
      },
      main_text: 'I just want to see how it fits into my life',
      additional_text: '',
      subscribe_data: '',
    },
    {
      id: 2,
      icon: {
        320: three_variant_2_icon_2,
        768: three_variant_2_icon_2_768,
      },
      main_text: "I'm ready to try some training",
      additional_text: '',
      subscribe_data: '',
    },
    {
      id: 3,
      icon: {
        320: three_variant_2_icon_3,
        768: three_variant_2_icon_3_768,
      },
      main_text: "I'm determined to improve my mental abilities",
      additional_text: '',
      subscribe_data: '',
    }
  ]
}

export const bge_data = [
  {
    id: 1,
    img: {
      320: emoji_1,
      768: emoji_768_1,
    },
    text: 'Badly'
  },
  {
    id: 2,
    img: {
      320: emoji_2,
      768: emoji_768_2,
    },
    text: 'Good'
  },
  {
    id: 3,
    img: {
      320: emoji_3,
      768: emoji_768_3,
    },
    text: 'Excellent'
  }
]

export const review_first_block_data = [
  {
    id: 1,
    h: 'Get your personal training plan to improve mental abilities',
    img: fb_1,
  },
  {
    id: 2,
    h: 'As you train, your mental abilities increase',
    img: fb_2,
  },
  {
    id: 3,
    h: 'Enjoy your workouts! Have fun and train your mind!',
    img: fb_3,
  },
]

export const review_able_to_data = [
  {
    id: 1,
    p: 'consistently achieve personal goals without unnecessary stress',
    img: at_1,
  },
  {
    id: 2,
    p: 'improve your emotional state, get rid of stress and anxiety',
    img: at_4,
  },
  {
    id: 3,
    p: 'keep your brain toned and increase your productivity',
    img: at_2,
  },
  {
    id: 4,
    p: 'gain important knowledge and skills',
    img: at_3,
  },
]

export const training_card_data = [
  {
      li: 'Duration',
      p: ['7 days'],
  },
  {
      li: 'Goal',
      p: ['Improve brain skills'],
  },
  {
      li: 'Minutes per day',
      p: ['10 minutes'],
  },
  {
      li: 'Main areas',
      p: ['Thought processes', 'Memory training'],
  },
]

export const awaits_you_slider_data = [
  {
    id: 1,
    h: 'Day 1',
    content: [
      {
        id: 1,
        img: icon_1,
        h: 'Selective perception',
        p: 'Focus on what is important while ignoring everything else',
      },
      {
        id: 2,
        img: icon_2,
        h: 'Approximate calculation',
        p: 'Choose more suitable options and make better decisions',
      },
      {
        id: 3,
        img: icon_3,
        h: 'Visual assessment',
        p: 'Make approximations to find the right answer',
      },
      {
        id: 4,
        img: icon_4,
        h: 'Visual processing speed',
        p: 'Accelerate your information processing speed',
      },
      {
        id: 5,
        img: icon_5,
        h: 'Spatial attention',
        p: 'Accelerate your information processing ability and visualization skills',
      },
    ]
  },
  {
    id: 2,
    h: 'Day 2',
    content: [
      {
        id: 1,
        img: icon_6,
        h: 'Spatial memory',
        p: 'Remember the location of things and navigate through familiar places',
      },
      {
        id: 2,
        img: icon_7,
        h: 'Decision-making',
        p: 'Find and make the right decisions faster',
      },
      {
        id: 3,
        img: icon_8,
        h: 'Visual assessment',
        p: 'Make approximations to find the right answer',
      },
      {
        id: 4,
        img: icon_9,
        h: 'Visual processing speed',
        p: 'Accelerate your information processing speed',
      },
      {
        id: 5,
        img: icon_10,
        h: 'Spatial attention',
        p: 'Accelerate your information processing ability and visualization skills',
      },
    ]
  },
  {
    id: 3,
    h: 'Day 3',
    content: [
      {
        id: 1,
        img: icon_11,
        h: 'Spatial memory',
        p: 'Remember the location of things and navigate through familiar places',
      },
      {
        id: 2,
        img: icon_12,
        h: 'Decision-making',
        p: 'Find and make the right decisions faster',
      },
      {
        id: 3,
        img: icon_1,
        h: 'Visual assessment',
        p: 'Make approximations to find the right answer',
      },
      {
        id: 4,
        img: icon_13,
        h: 'Visual processing speed',
        p: 'Accelerate your information processing speed',
      },
      {
        id: 5,
        img: icon_14,
        h: 'Spatial attention',
        p: 'Accelerate your information processing ability and visualization skills',
      },
    ]
  },
  {
    id: 4,
    h: 'Day 4',
    content: [
      {
        id: 1,
        img: icon_15,
        h: 'Spatial memory',
        p: 'Remember the location of things and navigate through familiar places',
      },
      {
        id: 2,
        img: icon_8,
        h: 'Decision-making',
        p: 'Find and make the right decisions faster',
      },
      {
        id: 3,
        img: icon_16,
        h: 'Visual assessment',
        p: 'Make approximations to find the right answer',
      },
      {
        id: 4,
        img: icon_17,
        h: 'Visual processing speed',
        p: 'Accelerate your information processing speed',
      },
      {
        id: 5,
        img: icon_11,
        h: 'Spatial attention',
        p: 'Accelerate your information processing ability and visualization skills',
      },
    ]
  },
  {
    id: 5,
    h: 'Day 5',
    content: [
      {
        id: 1,
        img: icon_18,
        h: 'Spatial memory',
        p: 'Remember the location of things and navigate through familiar places',
      },
      {
        id: 2,
        img: icon_2,
        h: 'Decision-making',
        p: 'Find and make the right decisions faster',
      },
      {
        id: 3,
        img: icon_9,
        h: 'Visual assessment',
        p: 'Make approximations to find the right answer',
      },
      {
        id: 4,
        img: icon_13,
        h: 'Visual processing speed',
        p: 'Accelerate your information processing speed',
      },
      {
        id: 5,
        img: icon_6,
        h: 'Spatial attention',
        p: 'Accelerate your information processing ability and visualization skills',
      },
    ]
  },
  {
    id: 6,
    h: 'Day 6',
    content: [
      {
        id: 1,
        img: icon_7,
        h: 'Spatial memory',
        p: 'Remember the location of things and navigate through familiar places',
      },
      {
        id: 2,
        img: icon_5,
        h: 'Decision-making',
        p: 'Find and make the right decisions faster',
      },
      {
        id: 3,
        img: icon_16,
        h: 'Visual assessment',
        p: 'Make approximations to find the right answer',
      },
      {
        id: 4,
        img: icon_17,
        h: 'Visual processing speed',
        p: 'Accelerate your information processing speed',
      },
      {
        id: 5,
        img: icon_8,
        h: 'Spatial attention',
        p: 'Accelerate your information processing ability and visualization skills',
      },
    ]
  },
  {
    id: 7,
    h: 'Day 7',
    content: [
      {
        id: 1,
        img: icon_19,
        h: 'Spatial memory',
        p: 'Remember the location of things and navigate through familiar places',
      },
      {
        id: 2,
        img: icon_17,
        h: 'Decision-making',
        p: 'Find and make the right decisions faster',
      },
      {
        id: 3,
        img: icon_18,
        h: 'Visual assessment',
        p: 'Make approximations to find the right answer',
      },
      {
        id: 4,
        img: icon_3,
        h: 'Visual processing speed',
        p: 'Accelerate your information processing speed',
      },
      {
        id: 5,
        img: icon_19,
        h: 'Spatial attention',
        p: 'Accelerate your information processing ability and visualization skills',
      },
    ]
  },
]

export const result_slider_data = [
  {
    id: 1,
    chart: chart_2,
    h: 'Attention',
    p: 'You are ready to focus on tasks that you consider important enough. At the same time, you may experience difficulty switching, get tired quickly and begin to get distracted.',
  },
  {
    id: 2,
    chart: chart_1,
    h: 'Problem Solving',
    p: 'You assess problems and make decisions well enough. But sometimes it takes up too much of your time. You may also have difficulty solving problems that require quick fixes or mental arithmetic.',
  },
  {
    id: 3,
    chart: chart_3,
    h: 'Memory',
    p: 'You grasp verbal information quite well. But you should work on keeping it in your memory and consciousness. Otherwise, it will be more difficult for you to learn, for example, foreign languages.',
  },
  {
    id: 4,
    chart: chart_4,
    h: 'Logic',
    p: "You know how to think logically and usually have no difficulty verbalizing your conclusions. But if you're pressed for time or stressed, it's much more difficult.",
  },
  {
    id: 5,
    chart: chart_5,
    h: 'Mental flexibility',
    p: "You easily perceive non-verbal information. However, you often experience difficulties with analysis, synthesis and generalization when it comes to images, sounds, forms, etc.",
  },
]

export const get_data = [
  {
    id: 1,
    icon: you_get_1,
    text: '25+ games & 500+ levels',
    small_text: 'Discover the power of your brain',
  },
  {
    id: 2,
    icon: you_get_2,
    text: 'Personalized daily training',
    small_text: 'Get the workouts that suit you',
  },
  {
    id: 3,
    icon: you_get_3,
    text: 'Popular riddles',
    small_text: 'Play favorite riddles without limits',
  },
  {
    id: 4,
    icon: you_get_4,
    text: 'Unlimited access',
    small_text: 'An unlimited access to all games',
  },
  {
    id: 5,
    icon: you_get_5,
    text: 'No ads',
    small_text: 'Develop your brain without pauses',
  },
]

export const end_block_data = [
  {
    id: 1,
    h: 'It can fit into your life',
    p: 'Each Brainy Train level takes less than five minutes to complete, so you can take it in small chunks or long blocks. If you want, you can set personal training goals and Brainy Train will send you workout reminders.',
    img: end_img_1,
    width: 300,
    height: 310,
    button: false,
  },
  {
    id: 2,
    h: 'Track your improvement',
    p: 'As you train, the program will adjust to your skills. You can measure your performance across skill groups and see how you compare with others.',
    img: end_img_2,
    width: 290,
    height: 350,
    button: true,
  },
]

export const subscription_data = [
  {
      id: 1,
      text: 'Weekly Plan',
      icon: subscription_icon_1,
      price: 7.99,
      discount: 15.99,
      look_up: 'week',
      popular: false,
  },
  {
      id: 2,
      text: 'Yearly Plan',
      icon: subscription_icon_2,
      price: 29.99,
      discount: 59.99,
      look_up: 'paid annually',
      popular: true,
  },
  {
      id: 3,
      text: 'Lifetime',
      icon: subscription_icon_3,
      price: 49.99,
      discount: 99.99,
      look_up: 'one-time payment',
      popular: false,
  }
]

export const train_benefits_data = [
  {
    id: 1,
    icon: train_benefits_icon_1,
    text: 'Individual training plan',
  },
  {
    id: 2,
    icon: train_benefits_icon_2,
    text: 'Progress statistics',
  },
  {
    id: 3,
    icon: train_benefits_icon_3,
    text: 'Mood enhancement',
  },
  {
    id: 4,
    icon: train_benefits_icon_4,
    text: 'Reducing anxiety',
  },
  {
    id: 5,
    icon: train_benefits_icon_5,
    text: 'Increased efficiency',
  },
]
