import { createRouter, createWebHistory } from 'vue-router'
import {redBrain, purpleBrain, blueBrain, greenBrain, yellowBrain} from '@/db';
import {welcome_cognitive_abilities, welcome_phrases, welcome_plan} from '@/db';
import {discribe_memory, discribe_problem_solving, discribe_attention, discribe_mental_flexibility, discribe_logical} from '@/db';
import {three_variant_intensely, three_variant_motivated} from '@/db';

const routes = [
  {
    meta: {actionOnReview: true},
    path: '/',
    name: 'startTraining',
    component: () => import('@/views/StartTrainingView.vue')
  },
  {
    path: '/quiz',
    name: 'quiz',
    component: () => import('@/views/QuizView.vue'),
    children: [
      {path: '', meta: [welcome_cognitive_abilities], component: () => import('@/components/quiz_view/QuizWelcomeTest.vue')},

      {path: '1-:count', params: {count: 2.5}, meta: [redBrain], component: () => import('@/components/quiz_view/QuizBrainTest.vue')},
      {path: '2-:count', params: {count: 2.5}, meta: [purpleBrain], component: () => import('@/components/quiz_view/QuizBrainTest.vue')},
      {path: '3-:count', params: {count: 2.5}, meta: [blueBrain], component: () => import('@/components/quiz_view/QuizBrainTest.vue')},
      {path: '4-:count', params: {count: 2.5}, meta: [greenBrain], component: () => import('@/components/quiz_view/QuizBrainTest.vue')},
      {path: '5-:count', params: {count: 2.5}, meta: [yellowBrain], component: () => import('@/components/quiz_view/QuizBrainTest.vue')},

      {path: '6-:count', meta: [welcome_phrases], params: {count: 74}, component: () => import('@/components/quiz_view/QuizWelcomeTest.vue')},

      {path: '7-:count', meta: [discribe_memory], params: {count: 80}, component: () => import('@/components/quiz_view/QuizFourVariant.vue')},
      {path: '8-:count', meta: [discribe_problem_solving], params: {count: 82}, component: () => import('@/components/quiz_view/QuizFourVariant.vue')},
      {path: '9-:count', meta: [discribe_attention], params: {count: 84}, component: () => import('@/components/quiz_view/QuizFourVariant.vue')},
      {path: '10-:count', meta: [discribe_mental_flexibility], params: {count: 86}, component: () => import('@/components/quiz_view/QuizFourVariant.vue')},
      {path: '11-:count', meta: [discribe_logical], params: {count: 88}, component: () => import('@/components/quiz_view/QuizFourVariant.vue')},

      {path: '12-:count', meta: [welcome_plan], params: {count: 90}, component: () => import('@/components/quiz_view/QuizWelcomeTest.vue')},

      {path: '13-:count', params: {count: 92}, component: () => import('@/components/quiz_view/QuizMentalGraph.vue')},

      {path: '14-:count', meta: [three_variant_intensely], params: {count: 94}, component: () => import('@/components/quiz_view/QuizThreeVariant.vue')},
      {path: '15-:count', meta: [three_variant_motivated], params: {count: 96}, component: () => import('@/components/quiz_view/QuizThreeVariant.vue')},

      {path: '16-:count', params: {count: 98}, component: () => import('@/components/quiz_view/QuizLoader.vue')},
      {path: '17-:count', params: {count: 100}, component: () => import('@/components/quiz_view/QuizRegistration.vue')}
    ]
  },
  {
    path: '/review',
    component: () => import('@/views/ReviewView.vue'),
  },
  {
    meta: {actionOnReview: false},
    path: '/subscribe',
    component: () => import('@/views/SubscribeView.vue'),
  },
  {
    meta: {actionOnReview: false},
    path: '/discount',
    component: () => import('@/views/DiscountSubscribeView.vue')
  },
  {
    path: '/payment',
    component: () => import('@/views/PaymentView.vue')
  },
  {
    path: '/test',
    component: () => import('@/views/LoaderAppView.vue')
  },
  {
    path: '/test_loader',
    component: () => import('@/components/review_view/ReviewTestSwiper.vue')
  },
  {
    path: '/success',
    component: () => import('@/views/SuccessPaymentView.vue')
  },
  {
    path: '/close',
    component: () => import('@/views/ClosePaymentView.vue')
  },
  {
    path: '/404',
    component: () => import('@/views/404View.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/404'
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior() {
    return {top: 0}
  },
  routes
})

export default router
